import React from "react"
import { Link, navigate } from "gatsby"
import {Container,Table, Dropdown} from 'react-bootstrap';
import dateFormat from 'dateformat';
import {config} from '../Common/constant';
import TableLineShrimmer from '../shrimmer/TableLineShrimmer';

import {isLoggedIn, isBrowser, logout, getUser } from "../../services/auth"

function SellerTR({property})
{
    const createLink = (property_id, seller_email, user_email) => {
        if (isBrowser()) {
            localStorage.setItem('create_present', JSON.stringify({'property_id':property_id, 'seller_email': seller_email, 'user_email':user_email}));
        }
    }
    var appBooked = property.appointment.DateAndTimeOfTheAppointment && dateFormat(property.appointment.DateAndTimeOfTheAppointment, "dd/mm/yy");
    var app_date = property.appointment.DateAndTimeOfTheAppointment && new Date(property.appointment.DateAndTimeOfTheAppointment);
    return(
        <tr>
            {/* <td><Link to={`${config.seller_details}/${property.seller_email}`} onClick={() => {createLink(property.id, property.seller_email, property.user_email)}}><strong>{property.seller_name ? property.seller_name : property.seller_email}</strong></Link></td> */}
            <td><strong>{property.seller_name ? property.seller_name : property.seller_email}</strong></td>
            <td><Link to={`${config.property_details}/${property.id}`} onClick={() => {createLink(property.id, property.seller_email, property.user_email)}}>{property.address}</Link></td>            
            <td>Pre-Valuation Presentation Sent</td>            
            <td>{appBooked}</td>            
        </tr>
    )
}


function SellerList(props){
    var j = 0;

    return(
    <>
        <Table className={`${props.seller_data && props.seller_data.length > 0 ? '' : 'no_rec_tbl_cls'}`} responsive>
            <tbody>
            {
                
                props.loading ? (
                    <>
                        <TableLineShrimmer item_count={3} />
                    </>

                ) :  props.seller_data && props.seller_data.properties.length > 0 && props.seller_data.properties.map((property, index) => {
                    //var SellerProperty = SellerProperty(seller.user_email);
                    var appBooked= property.appointment.DateAndTimeOfTheAppointment && dateFormat(property.appointment.DateAndTimeOfTheAppointment, "dd/mm/yy");
                    var app_date = property.appointment.DateAndTimeOfTheAppointment &&  new Date(property.appointment.DateAndTimeOfTheAppointment);

                    if(props.tab_actv === "all"){
                        j++;
                        return(
                            <SellerTR property={property} />
                        )
                        
                    }

                    if(props.tab_actv === "active" && app_date > new Date()){
                        j++;
                        return(
                            <SellerTR property={property} />
                        )                        
                    } 

                    if(props.tab_actv === "completed" && app_date < new Date()){
                        j++;
                        return(
                            <SellerTR property={property} />
                        ) 
                    }
                })
            }
            {
                !props.loading && j === 0 && (<td colSpan={4} className="noRecMsg">There are no records!</td>)
            }

            </tbody>
        </Table>

    </>
    )
}

export default SellerList
