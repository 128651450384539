import React, {useEffect, useState} from "react"
import {Container,Table, Dropdown} from 'react-bootstrap';
import { Link } from "gatsby";
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"

import SortIcon from "../assests/images/sort-icon.svg";

import { useContainerContext } from "../store/ContainerContext";
//import {getUser} from "../services/auth";
import SearchForm from "../components/modules/search-form";
import SellerList from "../components/modules/seller-list";

import { SELLER_LIST } from "../queries/common_use_query";

import dateFormat from 'dateformat';

const Seller = () => {

  const [sort_by, setSortBy] = useState('Most Recent');
  
  const context = useContainerContext();
  const { logged_user_data } = context.state;
  //const user = getUser();

  const [ search_text, setSearchText ] = useState('');
  const [ sort_order, setSortOrder ] = useState('createdAt:desc');
  const [ tab_actv, setTabActv ] = useState('all');

  const handlechange = (event) => {
    // remove initial empty spaces
    var search_val = event.target.value.trimStart();
    setSearchText(search_val);
  }

  const sortByClick = (sort_name, sort_order) => {
    setSortOrder(sort_order)
    setSortBy(sort_name);
  }

  const tabClick = (tab_name, e) => {
    setTabActv(tab_name);
  }

  const { loading, error, data } = SELLER_LIST(sort_order, logged_user_data.email, search_text);


  return(
    <>
      <SEO title="Seller" description="Seller" />
      <div className="dashboard-wrapper">
        <LeftNav page_name="Seller" />
        {/* sidebar */}

        <div className="dashboard-content seller_cnt">
          <Container>
              <div className="content-wrapper">
                <div className="top-content">
                    <h2><strong>Sellers</strong></h2>
                    
                    <SearchForm handlechange={handlechange} />
                </div>

                <div className="seller-tabs">
                    <ul>
                        <li><a href="javascript:;" className={tab_actv === "all" ? "actv" : "" } onClick={(e) => tabClick('all', e)}>All Sellers</a></li>
                        <li><a href="javascript:;" className={tab_actv === "active" ? "actv" : "" } onClick={(e) => tabClick('active', e)}>Active</a></li>
                        <li><a href="javascript:;" className={tab_actv === "completed" ? "actv" : "" } onClick={(e) => tabClick('completed', e)}>Completed</a></li>
                        <li className="sort_cls">
                            {/* <Link to="/"><img src={SortIcon} alt="Sort" />Most Recent</Link> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="">
                                    <img src={SortIcon} alt="Sort" />{sort_by}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="javascript:;" onClick={() => sortByClick('Most Recent', 'createdAt:desc')}>Most Recent</Dropdown.Item>
                                    <Dropdown.Item href="javascript:;" onClick={() => sortByClick('Oldest', 'createdAt:asc')}>Oldest</Dropdown.Item>
                                    {/* <Dropdown.Item href="javascript:;" onClick={() => sortByClick('Popular')}>Popular</Dropdown.Item> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                </div>
                {/* Tab Content */}

                {/* top-content */}
                <div className="card-block">
                    <div className="table-wrap">
                      <SellerList tab_actv={tab_actv} seller_data={data} loading={loading} />
                    </div>
                    {/* table-wrap */}
                </div>
                {/* card-block */}

              </div>
          </Container>
          {/* container */}
        </div>
        {/* dashboard-content */}
      </div>
    </>
  )

}

export default Seller